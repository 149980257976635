import {
	check,
	literal,
	maxLength,
	nullish,
	object,
	pipe,
	string,
	union,
} from 'valibot';
import { isPostalCode } from '../formUtils';

const postalCode = object({
	postalCode: nullish(
		union([
			pipe(
				string(),
				maxLength(20),
				check(isPostalCode, 'Postal code appears to be invalid.'),
			),
			literal(''),
		]),
	),
	country: nullish(pipe(string(), maxLength(255))),
});

export default postalCode;
